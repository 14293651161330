import React, { useEffect, useState, useRef } from "react";
import "../_styles.scss";

import { useParams } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";
import ReactLoading from "react-loading";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { BiFullscreen } from "react-icons/bi";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

function PortfolioDetails() {
    const [mediaList, setMediaList] = useState([]);
    const [mediaErrors, setMediaErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [isLightboxOpen, setIsLightboxOpen] = useState(false);
    const [lightboxIndex, setLightboxIndex] = useState(0);
    const [mainTitle, setMainTitle] = useState("");
    const [mainDesc, setMainDesc] = useState("");

    const mainSliderRef = useRef();
    const thumbnailSliderRef = useRef();

    let { title } = useParams();

    const { userClaims } = useAuth();

    useEffect(() => {
        const fetchPortfolioItemData = async () => {
            const docRef = doc(db, userClaims, title);
            const docSnap = await getDoc(docRef);
            let transformedMediaList = [];

            if (docSnap.exists()) {
                const firestoreData = docSnap.data();

                setMainTitle(firestoreData.mainTitle || "");
                setMainDesc(firestoreData.mainDesc || "");

                for (let i = 0; i < Object.keys(firestoreData).length / 2; i++) {
                    if (firestoreData[`fileUrl${i}`]) {
                        let mediaObject = {};
                        const isVideo = firestoreData[`fileName${i}`].includes(".mp4");
                        mediaObject.type = isVideo ? "video" : "img";
                        mediaObject.src = firestoreData[`fileUrl${i}`];
                        mediaObject.name = firestoreData[`fileName${i}`].split(".").slice(0, -1).join(".");

                        transformedMediaList.push(mediaObject);
                    }
                }

                setMediaList(transformedMediaList);
                setLoading(false);
            } else {
                console.log("No such document!");
                setLoading(false);
            }
        };

        fetchPortfolioItemData();
    }, [title, userClaims]);

    useEffect(() => {
        document.title = title;
    }, [title]);

    const mainSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        afterChange: index => thumbnailSliderRef.current.slickGoTo(index)
    };

    const thumbnailSettings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 7,
        slidesToScroll: 3,
        focusOnSelect: true,
        initialSlide: 0
    };

    const onError = (index) => {
        setMediaErrors((prevErrors) => ({
            ...prevErrors,
            [index]: true,
        }));
    }

    if (loading) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
                <ReactLoading className="loader" type="spokes" />
            </div>
        );
    }

    return (
        <div className="p-0 m-0 w-100 justify-content-center align-items-center">
            <Header />
            <div className="p-0 m-0 w-100 position-relative">
                <Link to="/portfolio" className="btn btn1 col-7 col-sm-4 col-md-3 m-0 my-1 mt-3 mt-md-5 ms-3 ms-md-5"><BsArrowLeftCircleFill className="me-1 mb-1" />back to portfolio</Link>
                <div className="py-2 py-md-3 px-4 px-md-5 m-0 mx-auto col-11 col-sm-10 col-lg-9 col-xl-7" style={{ overflow: "hidden" }}>
                    <h1 className="color2" dangerouslySetInnerHTML={{ __html: mainTitle }}></h1>

                    {mediaList.length > 0 && (
                        <>
                            <Slider ref={slider => (mainSliderRef.current = slider)} {...mainSettings}>
                                {mediaList.map((media, index) => (
                                    <div key={index} className="media-container">
                                        {media.type === "img" && (mediaErrors[index] ?
                                            <img src={process.env.PUBLIC_URL + "./images/placeholder-16-9.jpg"} alt="" /> :
                                            <>
                                                <img src={media.src} alt={media.name} onError={() => onError(index)} />
                                                <BiFullscreen className="fullscreen-icon" onClick={() => { setIsLightboxOpen(true); setLightboxIndex(index); }} />
                                            </>)}
                                        {media.type === "video" && (mediaErrors[index] ?
                                            <img src={process.env.PUBLIC_URL + "./images/video_player_placeholder.gif"} alt="" /> :
                                            <video controls onError={() => onError(index)}><source src={media.src} type="video/mp4" /></video>)}
                                    </div>
                                ))}
                            </Slider>

                            <div style={{ margin: "20px 1px 0" }}>
                                <Slider ref={slider => (thumbnailSliderRef.current = slider)} {...thumbnailSettings}>
                                    {mediaList.map((media, index) => (
                                        <div key={index} className="thumbnail-container" onClick={() => mainSliderRef.current.slickGoTo(index)}>
                                            {media.type === "img" && (mediaErrors[index] ?
                                                <img src={process.env.PUBLIC_URL + "./images/placeholder-16-9.jpg"} alt="" /> :
                                                <img src={media.src} alt={media.name} onError={() => onError(index)} />)}
                                            {media.type === "video" && (mediaErrors[index] ?
                                                <img src={process.env.PUBLIC_URL + "./images/video_player_placeholder.gif"} alt="" /> :
                                                <video onError={() => onError(index)}><source src={media.src} type="video/mp4" /></video>)}
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </>
                    )}
                </div>
                <div className="p-4 p-md-5 m-0 mx-auto col-11 col-sm-10 col-lg-9 col-xl-7">
                    <h2 className="color2">Description</h2>
                    <br />
                    <div className="mb-3" dangerouslySetInnerHTML={{ __html: mainDesc }} />
                </div>
            </div>
            <div className="p-0 m-0 the-border-1" />
            <Footer />

            {isLightboxOpen && (
                <Lightbox
                    imageLoadErrorMessage="This format of file is not supported in fullscreen mode"
                    mainSrc={mediaList[lightboxIndex].src}
                    nextSrc={mediaList[(lightboxIndex + 1) % mediaList.length].src}
                    prevSrc={mediaList[(lightboxIndex + mediaList.length - 1) % mediaList.length].src}
                    onCloseRequest={() => setIsLightboxOpen(false)}
                    onMovePrevRequest={() => setLightboxIndex((lightboxIndex + mediaList.length - 1) % mediaList.length)}
                    onMoveNextRequest={() => setLightboxIndex((lightboxIndex + 1) % mediaList.length)}
                />
            )}
        </div>
    );
}

export default PortfolioDetails;
