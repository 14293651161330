import React, { useEffect, useState, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import PortfolioDetails from "./pages/PortfolioDetails";
import "./_styles.scss";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AuthProvider } from "./AuthContext";
import Spinner from "./components/Spinner";

// Import pages using lazy
const Homepage = lazy(() => import("./pages/Homepage"));
const Services = lazy(() => import("./pages/Services"));
const Portfolio = lazy(() => import("./pages/Portfolio"));
const AboutUs = lazy(() => import("./pages/About_Us"));
const ContactUs = lazy(() => import("./pages/Contact_Us"));
const PrivacyPolicy = lazy(() => import("./pages/Privacy_Policy"));
const Error404 = lazy(() => import("./pages/Error404"));

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Content() {
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const timer = setTimeout(() => setIsLoading(false), 500);
    return () => clearTimeout(timer);
  }, [location]);

  return (
    <>
      <ScrollToTop />
      {isLoading ? (
        <Spinner />
      ) : (
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/portfolio/:title" element={<PortfolioDetails />} />
            <Route path="/services" element={<Services />} />
            <Route path="/portfolio" element={<Portfolio />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </Suspense>
      )}
    </>
  );
}

function App() {
  return (
    <Router>
      <AuthProvider>
        <Content />
        <ToastContainer position="top-center" />
      </AuthProvider>
    </Router>
  );
}

export default App;
