import React, { useState, useEffect, useRef } from "react";
import "../_styles.scss";

import { Link } from "react-router-dom";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { FaPhone } from "react-icons/fa";
import { GrContact } from "react-icons/gr";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../firebase";
import { useAuth } from "../AuthContext";

// Custom Hook to detect window width change
function useWindowWidth() {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
        const handleResize = () => setIsDesktop(window.innerWidth > 768);
        window.addEventListener("resize", handleResize);

        // Cleanup - remove the event listener
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return isDesktop;
}

function Header() {
    const [P0I0, setP0I0] = useState("");
    const [P0I0Alt, setP0I0Alt] = useState("");
    const [expanded, setExpanded] = useState(false);
    const isDesktop = useWindowWidth();
    const navRef = useRef();
    const { userClaims } = useAuth();

    const [BPhone1, setBPhone1] = useState("");
    const [BPhone2, setBPhone2] = useState("");

    // Effect to collapse navbar when clicking outside it
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (navRef.current && !navRef.current.contains(event.target)) {
                setExpanded(false);
            }
        };
        document.addEventListener("click", handleClickOutside);

        // Cleanup - remove the event listener
        return () => document.removeEventListener("click", handleClickOutside);
    }, [expanded]);

    // Effect to fetch data from database
    useEffect(() => {
        const fetchData = async () => {
            if (userClaims) {
                const docRefImg = doc(db, userClaims, "page-0");
                const docSnapImg = await getDoc(docRefImg);
                if (docSnapImg.exists()) {
                    const dataImg = docSnapImg.data();
                    setP0I0(dataImg.imageUrl0);
                    setP0I0Alt(dataImg.imageName0 || "Placeholder image");
                } else {
                    console.log("No such document!");
                }

                const docRefPhone = doc(db, userClaims, "usergi");
                const docSnapPhone = await getDoc(docRefPhone);
                if (docSnapPhone.exists()) {
                    const dataPhone = docSnapPhone.data();
                    setBPhone1(dataPhone.BPhone1);
                    setBPhone2(dataPhone.BPhone2);
                } else {
                    console.log("No such document!");
                }
            }
        };
        if (userClaims) {
            fetchData();
        }
    }, [userClaims]);

    const handleNavClick = () => !isDesktop && setExpanded(false);

    return (
        <>
            <div className="p-0 m-0 w-100">
                <div className="col-12 align-items-center justify-content-between d-flex" style={{ backgroundColor: "rgba(17, 80, 56, .8)" }}>
                    <div className="p-0 m-0 col-12 col-md-4 text-center">
                        <Link to="/">
                            <img src={P0I0} className="p-0 py-3 header-logo w-100 h-100" alt={P0I0Alt} />
                        </Link>
                    </div>
                    <div className="p-4 m-0 col-12 col-md-8 text-end d-none d-md-block">
                        {BPhone1 ? (
                            <a href={`tel:${BPhone1}`} rel="noopener noreferrer" className="btn btn2 w-auto align-items-center d-inline-flex me-3">
                                <FaPhone size={20} className="me-1" />{BPhone1}
                            </a>
                        ) : BPhone2 ? (
                            <a href={`tel:${BPhone2}`} rel="noopener noreferrer" className="btn btn2 w-auto align-items-center d-inline-flex me-3">
                                <FaPhone size={20} className="me-1" />{BPhone2}
                            </a>
                        ) : null}
                        <Link to="/contact-us" className="btn btn2 w-auto align-items-center d-inline-flex">CONTACT US NOW!</Link>
                    </div>
                </div>
                <div className="the-border-1" />
                <div className="p-0 m-0 w-100 nav-color">
                    <div className="p-0 m-0 mx-auto col-12 col-lg-8 text-center" ref={navRef}>
                        <Navbar className="p-0 m-0 justify-content-between" expand="md" expanded={expanded}>
                            <Link to="/contact-us" className="d-md-none ms-4 my-2">
                                <GrContact className="svg-icon" size={25} />
                            </Link>
                            {BPhone1 ? (
                                <a href={`tel:${BPhone1}`} className="d-md-none my-2">
                                    <FaPhone className="svg-icon" size={20} />
                                </a>
                            ) : BPhone2 ? (
                                <a href={`tel:${BPhone2}`} className="d-md-none my-2">
                                    <FaPhone className="svg-icon" size={20} />
                                </a>
                            ) : null}
                            <Navbar.Toggle
                                className="my-2 me-4"
                                aria-controls="basic-navbar-nav"
                                onClick={() => setExpanded(!expanded)}
                            >
                                <span style={{ backgroundImage: `url("./images/hamburger-icon.svg")` }} className="navbar-toggler-icon"></span>
                            </Navbar.Toggle>
                            <Navbar.Collapse id="basic-navbar-nav">
                                <div className="the-border-1 d-md-none" />
                                <Nav className="mx-auto">
                                    <Nav.Link as={Link} to="/" className="header-navbar" onClick={handleNavClick}>HOME</Nav.Link>
                                    <Nav.Link as={Link} to="/services" className="header-navbar" onClick={handleNavClick}>SERVICES</Nav.Link>
                                    <Nav.Link as={Link} to="/portfolio" className="header-navbar" onClick={handleNavClick}>PORTFOLIO</Nav.Link>
                                    <Nav.Link as={Link} to="/about-us" className="header-navbar" onClick={handleNavClick}>ABOUT US</Nav.Link>
                                    <Nav.Link as={Link} to="/contact-us" className="header-navbar" onClick={handleNavClick}>CONTACT US</Nav.Link>
                                </Nav>
                            </Navbar.Collapse>
                        </Navbar>
                    </div>
                </div>
            </div>
            <div className="the-border-1" />
        </>
    );
}

export default Header;
