import React, { createContext, useState, useEffect, useContext } from "react";
import { auth } from "./firebase";
import { signInWithEmailAndPassword, getIdTokenResult } from "firebase/auth";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const email = "skatebunny.contact@gmail.com";
    const password = "guDhvpZh57ngiB6";
    const [authenticated, setAuthenticated] = useState(false);
    const [error, setError] = useState("");
    const userClaims = "homefit-kitchen-and-bath";

    useEffect(() => {
        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                setAuthenticated(true);
                return getIdTokenResult(userCredential.user);
            })
            .catch((error) => {
                setError(error.message);
                setAuthenticated(false);
            });
    }, []);

    return (
        <AuthContext.Provider value={{ authenticated, error, userClaims }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);